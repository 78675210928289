<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
//import axios from 'axios';
import CourseModal from '@/components/modals/course-modal.vue';
import api from '@/helpers/api-rest/api.js';

export default {
    page: {
        title: "Cursos",
        meta: [{ name: "description", content: appConfig.description }]
    },
    components: { 
        Layout, 
        PageHeader,
        CourseModal
    },
  data() {
    return {
      tableData: [],
      title: "Cursos",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      fields: [
        { key: "mode_code", sortable: true, label: "Código" },
        { key: "education", sortable: true, label: "Enseñanza" },
        { key: "mode", sortable: false, label: "Modalidad" },
        // { key: "center.name", sortable: false, label: "Centro" },
        { key: "shift.name", sortable: false, label: "Tipo" },
        { key: "action", tdClass:"custom-table-action",  sortable: false, label: "" }
      ],
      showCenterModal: false,
      editMode: false,
      courseEdit: undefined,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    }
  },
  methods: {
    loadDataFromApi(){
      const apiEndpoint = `/api/v1/courses`;
      api.get({url:apiEndpoint})
        .then(response => {
          this.tableData = response?.data?.data;
        })
        .catch(error => {
          console.error("Error loading data:", error);
        });
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    success(course) {
      console.log(course);
      this.loadDataFromApi();
    },

    addCourse() {
      this.courseEdit = undefined;
    },

    editCourse(course) {
        this.courseEdit = course;
    },

  },
  mounted() {
    this.loadDataFromApi();
    // Set the initial number of items
    this.totalRows = this.tableData.length;
  },
}
</script>

<template>
  <Layout>
    <PageHeader :title="title">
      <template v-slot:header-button>
        <button v-b-modal.ModalCourse @click="addCourse()" class="btn btn-success">
          <i class="fa fa-plus"></i> Añadir Curso
        </button>
      </template>
    </PageHeader>
    <CourseModal :course="courseEdit" @success="success" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="card-title">Data Table</h4> -->
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Mostrar&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;registros
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Buscar..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                ref="courseTable"
              >
                <template v-slot:cell(action)="row">
                  <div class="text-center">
                    <a href="javascript:void(0);" class="mr-3 text-secondary" v-b-modal.ModalCourse title="Edit" @click="editCourse(row.item)">
                      <i class="mdi mdi-square-edit-outline font-size-18"></i>
                    </a>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>