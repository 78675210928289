<script>
import api from '@/helpers/api-rest/api.js';
// import Multiselect from "vue-multiselect";

export default {
    props:{
        course: {type: Object, default: () => {
            return {
                mode_code: '',
                education: '',
                mode: '',
                center_id: null,
                shift_id: "",
                selectedCenter: '',
            }
        }}
    },
    // components: { Multiselect },
    data() {
        return {
            selectOptionsCenter: [],
            selectOptionsCourseType: []
        };
    },
    computed: {
        modalTitle() {
        return this.course?.id ? 'Editar curso' : 'Añadir curso';
        },
        submitButtonText() {
            return this.course?.id ? 'Guardar' : 'Añadir';
        },
        selectedCenter: {
            get() {
                return this.selectOptionsCenter.find(center => center.id === this.course.center_id) || null;
            },
            set(value) {
                this.course.center_id = value ? value.id : null;
            }
        }
    },
    created() {
        this.loadSelectOptionsCenter();
        this.loadSelectOptionsCourseType();
    },
    methods: {
        loadSelectOptionsCourseType(){
            const apiEndpoint = `/api/v1/course-type-shifts`;
            api.get({url:apiEndpoint})
            .then(response => {
                this.selectOptionsCourseType = response?.data?.data;
            })
            .catch(error => {
                console.error("Error al cargar los tipos de cursos:", error);
            });
        },
        loadSelectOptionsCenter(){
            const apiEndpoint = `/api/v1/centers`;
            api.get({url:apiEndpoint})
            .then(response => {
                this.selectOptionsCenter = response?.data?.data;
            })
            .catch(error => {
                console.error("Error al cargar los centros:", error);
            });
        },
        createCourse() {
            const apiEndpoint = `/api/v1/courses`; 
            api.post({url:apiEndpoint, data:this.course})
                .then(response => {
                    const newCourse = response?.data;
                    this.$emit('success', newCourse);
                    this.$bvModal.hide("ModalCourse");
                })
                .catch(error => {
                    console.error("Error al crear el curso:", error);
                });
        },

        updateCourse() {
            const apiEndpoint = `/api/v1/courses`;
            api.put({url:apiEndpoint, data:this.course, id:this.course.id})
            .then(response => {
                const updatedCourse = response?.data;
                this.$emit('success', updatedCourse);
                this.$bvModal.hide("ModalCourse");
            })
            .catch(error => {
                console.error("Error al editar el curso:", error);
            });
        },

        submitForm() {
            if(this.course?.id){
                return this.updateCourse();
            }
            return this.createCourse();
        },
        cancelForm() {
            this.$bvModal.hide("ModalCourse");
            this.$emit('close-modal');
        },

        updateCenterId(selected) {
            this.course.center_id = selected ? selected.id : null;
            console.log(this.course);
        }
    }
}
</script>
<template>
    <b-modal id="ModalCourse" :title="modalTitle">  
      <template #default="{  }">
        <form @submit.prevent="submitForm">
            <div class="form-group">
                <label for="code">Código:</label>
                <input v-model="course.mode_code" type="text" class="form-control" id="code" required>
            </div>
            <div class="form-group">
                <label for="education">Enseñanza:</label>
                <input v-model="course.education" type="text" class="form-control" id="education" required>
            </div>
            <div class="form-group">
                <label for="mode_code">Modalidad:</label>
                <input v-model="course.mode" type="text" class="form-control" id="mode_code" required>
            </div>
            <!-- <div class="form-group">
                <label for="selectedCenter">Centro:</label>
                <multiselect 
                    placeholder="Selecciona..."
                    v-model="selectedCenter"
                    :options="selectOptionsCenter" 
                    label="name"
                    track-by="id">
                </multiselect>
            </div> -->
            <div class="form-group">
                <label for="selectedCourseType">Tipo de curso:</label>
                <select v-model="course.shift_id" class="form-control" id="selectedCourseType">
                    <option value="">Selecciona...</option>
                    <option v-for="option in selectOptionsCourseType" :value="option.id" :key="option.id">{{ option.name }}</option>
                </select>
            </div>
        </form>
      </template>
  
      <template #modal-footer="{  }">
        <b-button variant="primary" @click="submitForm()">{{ submitButtonText }}</b-button>
        <b-button @click="cancelForm()">Cancel</b-button>
      </template>
    </b-modal>
</template>
